<!-- =========================================================================================
  File Name: DataListThumbView.vue
  Description: Data List - Thumb View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="data-list-thumb-view" class="data-list-container">
    <vs-table

      ref="table"
      pagination
      search
      :max-items="itemsPerPage"
      :data="products"
    >
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
        <!-- ITEMS PER PAGE -->
        <div class="flex">

          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div
              class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
            >
              <span class="mr-2"
                >{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
                {{
                  products.length - currentPage * itemsPerPage > 0
                    ? currentPage * itemsPerPage
                    : products.length
                }}
                of {{ queriedItems }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item @click="itemsPerPage = 4">
                <span>4</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 10">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 15">
                <span>15</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 20">
                <span>20</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
      </div>

      <template slot="thead">
        <vs-th>الصورة</vs-th>
        <vs-th sort-key="name">الإسم</vs-th>
        <vs-th sort-key="main_category">القسم الرئيسي</vs-th>
        <vs-th sort-key="sub_category">القسم الفرعي</vs-th>
        <vs-th sort-key="sku">SKU</vs-th>
        <vs-th sort-key="price">السعر</vs-th>
        <vs-th>العمليات</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td
              class="table-image-container flex justify-center items-center"

            >
              <img :src="tr.firstMedia.path" class="table-image" />
            </vs-td>

            <vs-td>
              <a class="product-name font-medium truncate" href="#" @click="$router.push({name: 'edit-product',params: { id: tr.id }})">{{ tr.title }}</a>
            </vs-td>

            <vs-td>
              <p class="product-category">{{ tr.category.name }}</p>
            </vs-td>

            <vs-td>
              <p class="product-category">{{ tr.subCategory.name }}</p>
            </vs-td>
            <vs-td>
              <p class="product-category">{{ tr.sku }}</p>
            </vs-td>

            <vs-td>
              <p class="product-price">{{ tr.price }}LYD</p>
            </vs-td>

            <vs-td>
              <a @click="showUsers(tr.id)">
                <feather-icon
                  icon="UserIcon"
                  title="عرض المستخدمين"
                  svgClasses="w-5 h-5 hover:text-primary stroke-current"
                />
              </a>
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>

  </div>
</template>

<script>
import {mapActions} from 'vuex'

export default {

  data() {
    return {
      search: "",
      isMounted: false,
      itemsPerPage: 5,
      product_id: "",
    };
  },

  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    queriedItems() {
      return this.$refs.table
        ? this.$refs.table.queriedResults.length
        : this.products.length;
    },
    products(){
      return this.$store.state.wishlist.products
    }
  },

  methods: {
    ...mapActions('wishlist', [
      'fetchWishlistProducts',
    ]),
    showUsers(id) {
      this.$router.push({
        name: "wishlist-users",
        params: {id:id}
      });
    },
  },
  mounted() {

  },
  created() {
    this.fetchWishlistProducts()
  },
};
</script>

<style lang="scss">
#data-list-thumb-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);

        td {
          padding: 10px;

          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }

          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }

          &.table-image-container {
            height: 110px;

            // background: #fff;

            span {
              display: flex;
              justify-content: flex-start;
            }

            .table-image {
              height: 68px;
            }
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
